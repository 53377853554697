(function ( $ ) {
  $.fn.fullScreenMenu = function( options ) {
    // オプション設定（属性: 値）
    var defaults = {
      buttonId: "js-fullScreenMenuButton",
      breakpoint: "",
    };
    var settings = $.extend( {}, defaults, options );
    var that = this;

    return this.each(function() {
      // メイン処理

      //ボタンを生成
      $("body").append('<div id="'+settings.buttonId+'"><div id="hamburger"><span class="top"></span> <span class="middle"></span> <span class="bottom"></span></div></div>');

      // var breakpoint_flag = settings.breakpoint ? settings.breakpoint >= $(window).width() : true;

      // メニューを開く
      $('#'+settings.buttonId).add('.l-header-navi-list-item a').on('click', function(){
        if(settings.breakpoint >= $(window).width() || settings.breakpoint == "") {
          // var menuOpenFlag = $("body").hasClass("is-close");
          if(!($("body").hasClass("is-open"))) {
            $(".top-productList__slider").hide();
            $(that).fadeIn(250);
            $("body").removeClass("is-close").addClass("is-open");
            // 現在のスクロール位置を取得
            $('html').data('scrTop', $(window).scrollTop());//メニューを開いた位置を保存
            $('body,html').css({"position":"fixed","top": -1 * $('html').data('scrTop'),"height":"100%"});
          } else {
            $("body").removeClass("is-open").addClass("is-close");
            $('body,html').css({"position":"static","overflow":"visible","height":"auto"});
            setTimeout(function() {
              $("body").removeClass("is-close");
            }, 300);
            // メニューを開いた時点のスクロール位置を反映
            $(window).scrollTop($('html').data('scrTop'));//メニューを開いた位置へ移動
            $(that).fadeOut(250);
            setTimeout(function(){
              $(".top-productList__slider").show();
      			},260);
          }
        }
      });

      // $(window).on('resize', function(){
      // 	if(settings.breakpoint >= $(window).width() || settings.breakpoint == "") {
      //         if(settings.breakpoint == "" && $("body").hasClass("is-open")) {
      //             $(that).show();
      //         } else {
      //             $(that).hide();
      //         }
      // 	} else {
      // 		$(that).show();
      // 	}
      // }).resize();

    });
  };
}(jQuery));
