var StickyState = require('sticky-state');
new StickyState(document.querySelectorAll('.sticky'));


// var start_position = "";
// var offset = 300;
//
// var selector = $('.sticky-wrap').length == 0 ? $(".l-sub-fixed") : $('.sticky-wrap');
// // console.log(selector);
// // var scroll_position =
//
// $(window).on('scroll', function(){
//   if(selector.prev().length) {
//     start_position = selector.prev().offset().top + selector.prev().outerHeight(true);
//   } else {
//     start_position = selector.closest("#l-sub").offset().top;
//   }
//
//   if(start_position + offset < $(window).scrollTop()) {
//     $(".l-sub-fixed").css({
//       'position': 'static',
//       'padding-top': offset + 'px'
//     })
//   } else {
//     $(".l-sub-fixed").removeAttr('style');
//     // $(".l-sub-fixed").css({
//     //   'position': 'sticky',
//     //   'padding-top': '0'
//     // })
//
//   }
// }).trigger('scroll');
