import smoothScroll from '../../plugins/smooth-scroll.js';

//アンカーリンクの場合data-scrollをつける
$("a[href*='#']").each(function(){
  var href = $(this).attr('href');
  if(href && href !== "#") {
    var id = href.substring(href.indexOf("#"),href.length);
    if(($(id).length)){
      // console.log(href)
      $(this).attr('data-scroll', '');
    }
  }
});
//data-scrollがついているものはスムーススクロール
smoothScroll.init({
  speed: 500,
  offset: $(window).width() >= 1024 ? 30 : 80
});


//別ページからのページ内リンク
var hash = location.hash.slice(1);
var target = "#"+hash;
if(!(target == "#")) {
  if($(target).length) {
    $(window).on('load', function(){
      var position = $(target).offset().top - ($(window).width() >= 1024 ? 30 : 80);
      console.log(position);
      // $('html,body').show();
      $("html, body").animate({scrollTop:position}, 10, "swing");
    });
  }
}
