import {TweenMax} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


var controller = new ScrollMagic.Controller();

if($(".top-hero-slider").length) {
  var scene_hero = new ScrollMagic.Scene({
    triggerElement: ".top-hero-slider" ,
    triggerHook: 'onEnter',
    // duration: 800,
    offset :0
  })
  // .addIndicators()
  .addTo(controller);

  scene_hero.on('enter', ()=>{
    var tl = new TimelineMax();
    tl
    .to('.top-hero-slider', 1.2, {alpha: 1, x: 0, ease: Power2.easeOut, delay: 0.8})
    .to(".top-hero-icon", 0.6, {alpha: 0.8, delay: -0.2})
    .to(".top-hero-eventInfo", 0.5, {alpha: 1, delay: -0.2})
    //triggerElementを過ぎたとき
  });
}

if($(".top-topics").length) {
  var scene_topics = new ScrollMagic.Scene({
    triggerElement: ".top-topics" ,
    triggerHook: 'onCenter',
    // duration: 800,
    offset :0
  })
  // .addIndicators()
  .addTo(controller);

  scene_topics.on('enter', ()=>{
    var tl = new TimelineMax();
    tl
    .staggerTo('.c-list-topics-item', 0.9, {alpha: 1, y: 0}, 0.3)
    //triggerElementを過ぎたとき
  });
}

if($(".top-vision").length) {
  var scene_vision = new ScrollMagic.Scene({
    triggerElement: ".top-vision" ,
    triggerHook: 'onCenter',
    // duration: 800,
    offset :0
  })
  // .addIndicators()
  .addTo(controller);

  scene_vision.on('enter', ()=>{
    var tl = new TimelineMax();
    tl
    .to('.top-vision-content-image', 0.6, {alpha: 1})
    .to('.top-vision-content-detail', 0.6, {alpha: 1, y: 0, delay: -0.2})
    //triggerElementを過ぎたとき
  });
}

if($(".top-info").length) {
  var scene_info = new ScrollMagic.Scene({
    triggerElement: ".top-info" ,
    triggerHook: 'onCenter',
    // duration: 800,
    offset :0
  })
  // .addIndicators()
  .addTo(controller);

  scene_info.on('enter', ()=>{
    var tl = new TimelineMax();
    tl
    .staggerTo('.c-list-info-item', 0.9, {alpha: 1, y: 0}, 0.3)
    //triggerElementを過ぎたとき
  });
}

if($(".top-doctors").length) {
  var scene_doctors = new ScrollMagic.Scene({
    triggerElement: ".top-doctors" ,
    triggerHook: 'onCenter',
    // duration: 800,
    offset :0
  })
  // .addIndicators()
  .addTo(controller);

  scene_doctors.on('enter', ()=>{
    var tl = new TimelineMax();
    tl
    .to('.top-doctors-content-image', 0.6, {alpha: 1})
    .to('.top-doctors-content-detail', 0.6, {alpha: 1, y: 0, delay: -0.2})
    //triggerElementを過ぎたとき
  });
}

if($(".top-clinicInfo").length) {
  var scene_clinicInfo = new ScrollMagic.Scene({
    triggerElement: ".top-clinicInfo" ,
    triggerHook: 'onCenter',
    // duration: 800,
    offset :0
  })
  // .addIndicators()
  .addTo(controller);

  scene_clinicInfo.on('enter', ()=>{
    var tl = new TimelineMax();
    tl
    .to('.top-clinicInfo-content', 0.9, {alpha: 1, y: 0})
    //triggerElementを過ぎたとき
  });
}

if($(".top-concept").length) {
  var scene_concept = new ScrollMagic.Scene({
    triggerElement: ".top-concept" ,
    triggerHook: 'onCenter',
    // duration: 800,
    offset :0
  })
  // .addIndicators()
  .addTo(controller);

  scene_concept.on('enter', ()=>{
    var tl = new TimelineMax();
    tl
    .to('.top-concept-content-image', 0.6, {alpha: 1})
    .to('.top-concept-content-detail', 0.6, {alpha: 1, y: 0, delay: -0.2})
    //triggerElementを過ぎたとき
  });
}

if($(".top-service").length) {
  var scene_service = new ScrollMagic.Scene({
    triggerElement: ".top-service" ,
    triggerHook: 'onCenter',
    // duration: 800,
    offset :0
  })
  // .addIndicators()
  .addTo(controller);

  scene_service.on('enter', ()=>{
    var tl = new TimelineMax();
    tl
    .staggerTo('.c-list-service-item', 0.9, {alpha: 1, y: 0}, 0.3)
    //triggerElementを過ぎたとき
  });
}

if($(".top-products").length) {
  var scene_products = new ScrollMagic.Scene({
    triggerElement: ".top-products" ,
    triggerHook: 'onCenter',
    // duration: 800,
    offset :0
  })
  // .addIndicators()
  .addTo(controller);

  scene_products.on('enter', ()=>{
    var tl = new TimelineMax();
    tl
    .staggerTo('.c-list-products-item', 0.9, {alpha: 1, y: 0}, 0.3)
    //triggerElementを過ぎたとき
  });
}

if($(".top-conferenceRoom").length) {
  var scene_conferenceRoom = new ScrollMagic.Scene({
    triggerElement: ".top-conferenceRoom" ,
    triggerHook: 'onCenter',
    // duration: 800,
    offset :0
  })
  // .addIndicators()
  .addTo(controller);

  scene_conferenceRoom.on('enter', ()=>{
    var tl = new TimelineMax();
    tl
    .to('.top-conferenceRoom-content-image', 0.6, {alpha: 1})
    .to('.top-conferenceRoom-content-detail', 0.6, {alpha: 1, y: 0, delay: -0.2})
    //triggerElementを過ぎたとき
  });
}
