// import $ from 'jquery';
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';
import flexibility from 'flexibility';
// import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import isMobile from './modules/common/is-mobile.js';
import detectUseStyle from './modules/common/detectUseStyle.js';
import './modules/common/smoothScroll.js';
// import './modules/common/lazyload.js';
import './modules/common/userAgent.js';
import './modules/common/sp-menu.js';//スマホメニュー
import './modules/common/megamenu.js';//メガメニュー
import './modules/common/datepicker.js';
import './modules/common/tablefix.js';
// import './modules/common/highlight.js';
import './modules/common/scrollPageTop.js';
import './modules/common/sticky-polyfill.js';
import './modules/common/wp_contact-form-7.js';
import './modules/top/hero-swiper.js';
import './modules/top/animation.js';

flexibility(document.documentElement);//flexboxのie対応
objectFitImages();//object-fitのie対応
svg4everybody();//svgをieで表示

//電話番号スマホのみ掛ける
$("a[href*='tel']").on("click", function(e){
  if(!isMobile(768)){
    return false
  }
});


//typekit
(function(d) {
  var config = {
    kitId: 'aml3myj',
    scriptTimeout: 3000,
    async: true
  },
  h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wfinactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
})(document);
// A-OTF Futo Min A101 Pr6N
// font-family: a-otf-futo-min-a101-pr6n, serif;
// font-weight: 400;
// font-style: normal;
// ———————————————————————————————————————
// Toppan Bunkyu Midashi Go StdN
// font-family: toppan-bunkyu-midashi-go-std, sans-serif;
// font-weight: 900;
// font-style: normal;
// ———————————————————————————————————————
// Toppan Bunkyu Gothic Pr6N
// font-family: toppan-bunkyu-gothic-pr6n, serif;
// font-weight: 400;
// font-style: normal;
//============typekit




// const Snap = require(`imports-loader?this=>window,fix=>module.exports=0!snapsvg/dist/snap.svg.js`);
//
// var s3 = Snap('#svg-num');
// var svg_num_r = s3.select('#svg-num-r');
//
// svg_num_r.animate({'stroke-dashoffset': 0},5000, function () {
//   // console.log("222");
//   svg_num_r.animate({'fill-opacity': 1},800, function () {
//     // fadeOut();
//   });
// });


// function imageGuard(selector){
//
//   var guard_selector = document.querySelectorAll(selector);
//
//   for(var n = 0; n < guard_selector.length; n++){
//     guard_selector[n].addEventListener("contextmenu", function(e){
//       e.preventDefault();
//     }, false);
//   }
//
//   var guard_style = {
//     'pointer-events':'none',
//     '-webkit-touch-callout':'none',
//     '-moz-touch-callout':'none',
//     'touch-callout':'none',
//     '-webkit-user-select':'none',
//     '-moz-user-select':'none',
//     'user-select':'none'
//   }
//
//   Object.keys(guard_style).forEach(function(v, i, a){
//     for(var n = 0; n < guard_selector.length; n++){
//       guard_selector[n].style[v] = guard_style[v];
//     }
//   });
//
// }
