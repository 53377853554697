import swiper from 'swiper';

var topHero = new swiper('.js-top-hero-slider', {
  effect: "fade",
  speed: 1000,
  autoplay: {
    delay: 5500,
    disableOnInteraction: false,
  },
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  },
});
